<template>
  <div class="pt-4 px-2">
    <router-link
      :to="{ name: 'UserProfile', params: { type: $route.params.type, id: $route.params.id } }"
      class="back-link"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      {{userName}}
    </router-link>

    <assign-number-component
      @userName="v => userName = v"
      @changed="v => changed = v"
      @error="v => error = v"
      @onSuccess="onSuccess"
    />
    <header-buttons :changed="changed" :disabled="error !== ''" />
  </div>
</template>

<script>
import HeaderButtons from '@/components/HeaderButtons'
import AssignNumberComponent from '@/components/AssignNumberComponent'

export default {
  name: 'AssignNumber',
  components: {
    HeaderButtons,
    AssignNumberComponent
  },
  data: () => ({
    userName: '',
    changed: false,
    error: ''
  }),
  methods: {
    onSuccess () { // FONIO-2726
      setTimeout(() => {
        this.$router.push({ name: 'UserProfile', params: { type: this.$route.params.type, id: this.$route.params.id } })
      }, 3000)
    }
  }
}
</script>
